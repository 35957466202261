import React, { useState } from "react";
import { errorToast } from "../../Utility/Toast";
import { addUserRequirement } from "../../../services/UserRequirements.service";

export const Header = (props) => {
  const [step, setStep] = useState(1);
  const [role, setRole] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const isValidPhone = (phone) => /^[0-9]{10}$/.test(phone); // Checks for a 10-digit number
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); // Basic email regex
  const isValidWhatsApp = (number) => /^[0-9]{10,15}$/.test(number); // 10-15 digit WhatsApp number
  const handleSubmit = async () => {
    const formData = {
      role,
      whatsApp,
      companyName,
      name: fullName,
      phone,
      email,
      state,
      city,
      address,
      SourceName: "LANDING",
    };

    // Basic validation functions

    // Validation checks
    if (!role) return errorToast("Please select your role");
    if (!whatsApp || !isValidWhatsApp(whatsApp))
      return errorToast("Please enter a valid WhatsApp number (10-15 digits)");
    if (!companyName || companyName.trim().length < 2)
      return errorToast("Company name must be at least 2 characters long");
    if (!fullName || fullName.trim().length < 3)
      return errorToast("Full name must be at least 3 characters long");
    if (!phone || !isValidPhone(phone))
      return errorToast("Please enter a valid 10-digit phone number");
    if (!email || !isValidEmail(email))
      return errorToast("Please enter a valid email address");
    if (!state) return errorToast("Please enter your state");
    if (!city) return errorToast("Please enter your city");
    if (!address || address.trim().length < 5)
      return errorToast("Address must be at least 5 characters long");

    try {
      let { data: res } = await addUserRequirement(formData);
      if (res?.success) {
        console.log(res, "Submission successful");
        setStep(3); // Proceed to confirmation step
      } else {
        errorToast(res?.message || "Submission failed");
      }
    } catch (error) {
      console.error(error);
      errorToast(error.message || "An error occurred during submission");
    }
  };

  const nextStep = () => {
    if (!role) return errorToast("Please select your role");
    if (!whatsApp || !isValidWhatsApp(whatsApp))
      return errorToast("Please enter a valid WhatsApp number (10-15 digits)");
    setStep(2);
  };
  const prevStep = () => {
    setStep(1);
  };

  const Step1Form = () => {
    const [selectedOption, setSelectedOption] = useState("");

    const handleOptionClick = (option) => {
      setSelectedOption(option);
    };

    const handleInputChange = (e) => {
      setWhatsApp(e.target.value);
    };

    return (
      <div className="step1-form">
        <h2>Who are you?</h2>
        <div className="options">
          {[
            "Manufacturer/Importer",
            "Distributor",
            "Dealer",
            "Retailer",
            "Contractor",
          ].map((option) => (
            <button
              key={option}
              className={`option-button ${role === option ? "selected" : ""}`}
              onClick={() => setRole(option)}
            >
              {option}
            </button>
          ))}
        </div>
        <div className="input-section">
          <label htmlFor="whatsApp">Please provide your WhatsApp</label>
          <input
            type="number"
            id="whatsApp"
            maxLength={10}
            placeholder="Enter WhatsApp number"
            value={whatsApp}
            onChange={(e) => setWhatsApp(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="input-section">
          <button
            type=""
            className="btn btn-primary"
            onClick={() => nextStep()}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const Step3Form = () => {
    const [selectedOption, setSelectedOption] = useState("");

    const handleOptionClick = (option) => {
      setSelectedOption(option);
    };

    const handleInputChange = (e) => {
      setWhatsApp(e.target.value);
    };

    return (
      <div className="step1-form">
        <h2>Step 3: Confirmation</h2>
        <p>Thanks for your submission</p>
      </div>
    );
  };
  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1Form />;

      case 3:
        return <Step3Form />;
      default:
        return null;
    }
  };

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="intro-text">
            <div className="row ">
              <div className="col-md-8 col-sm-12">
                <img
                  src="./../img/plywoodmazarmobile.jpg"
                  className="mobile-header"
                />
                {/* <div className="">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>
              </div> */}
              </div>

              <div className="col-md-4 col-sm-12 ">
                <div className="stepper-form">
                  <div className="form-content">
                    {step == 1 && (
                      <div className="step1-form">
                        <h2>Who are you?</h2>
                        <div className="options">
                          {[
                            "Manufacturer/Importer",
                            "Distributor",
                            "Dealer",
                            "Retailer",
                            "Contractor",
                          ].map((option) => (
                            <button
                              key={option}
                              className={`option-button ${
                                role === option ? "selected" : ""
                              }`}
                              onClick={() => setRole(option)}
                            >
                              {option}
                            </button>
                          ))}
                        </div>
                        <div className="input-section">
                          <label htmlFor="whatsApp">
                            Please provide your WhatsApp
                          </label>
                          <input
                            type="number"
                            id="whatsApp"
                            placeholder="Enter WhatsApp number"
                            value={whatsApp}
                            onChange={(e) => setWhatsApp(e.target.value)}
                            className="form-control"
                          />
                        </div>
                        <div className="input-section">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => nextStep()}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}
                    {step == 2 && (
                      <div className="step1-form">
                        <h2>Contact Information</h2>
                        <div className="input-section">
                          <label htmlFor="companyName">Company Name</label>
                          <input
                            type="text"
                            id="companyName"
                            placeholder="Enter Company Name"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            className="form-control"
                          />
                        </div>
                        <div className="input-section">
                          <label htmlFor="fullName">Full Name</label>
                          <input
                            type="text"
                            id="fullName"
                            placeholder="Enter Full Name"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            className="form-control"
                          />
                        </div>
                        <div className="input-section">
                          <label htmlFor="phone">Phone</label>
                          <input
                            type="number"
                            id="phone"
                            placeholder="Enter Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="form-control"
                          />
                        </div>
                        <div className="input-section">
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            id="email"
                            placeholder="Enter Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                          />
                        </div>
                        <div className="input-section">
                          <label htmlFor="state">State</label>
                          <input
                            type="text"
                            id="state"
                            placeholder="Enter State"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            className="form-control"
                          />
                        </div>
                        <div className="input-section">
                          <label htmlFor="city">City</label>
                          <input
                            type="text"
                            id="city"
                            placeholder="Enter City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            className="form-control"
                          />
                        </div>
                        <div className="input-section">
                          <label htmlFor="address">Address</label>
                          <input
                            type="text"
                            id="address"
                            placeholder="Enter Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className="form-control"
                          />
                        </div>
                        <div className="form-navigation">
                          <button
                            className="btn btn-primary my-4"
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}

                    {step == 3 && <Step3Form />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

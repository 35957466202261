import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

let serverUrl = `${url}/userTopup`;


export const buyTopup = async (obj) => {
		let tempobj = {
		...obj,
		SourceName:'WEB'
	}

    return axiosApiInstance.post(`${serverUrl}/buyTopup`, tempobj)
}

export const getAllSubscriptionbyUserId = async (query) => {
    return axiosApiInstance.get(`${serverUrl}/getAllTopupbyUserId?${query}`)
}




export const usersubscriptionMailId = async (id) => {
    return axios.get(`${serverUrl}/sendMailById/${id}`)
}

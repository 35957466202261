import React, { createContext, useState } from 'react';

export const SignModalContext = createContext();

export const SignModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openSignModal = () => setIsModalOpen(true);
  const closeSignModal = () => setIsModalOpen(false);

  return (
    <SignModalContext.Provider value={{ isModalOpen, openSignModal, closeSignModal }}>
      {children}
    </SignModalContext.Provider>
  );
};

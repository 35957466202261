import { axiosApiInstance } from "../App";
import { url } from "./url.service";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { usePagination } from "../hooks/usePagination";

let serverUrl = `${url}/requirement`;

export const AddRequirementApi = async (obj) => {
  return axiosApiInstance.post(`${serverUrl}/addRequirement`, obj);
};
export const getRequirements = async () => {
  return axiosApiInstance.get(`${serverUrl}`);
};
export const getAllRequirements = async (query) => {
  return axiosApiInstance.get(`${serverUrl}/?${query}`);
};
export const productsyoumaylike = async (query) => {
  return axiosApiInstance.get(`${serverUrl}/getRequirementYouMayLike?${query}`);
};

const getTopRequirement = (pagination, searchObj) => {
  const query = new URLSearchParams({
    perPage: String(pagination.perPage),
    page: String(pagination.page),
    ...searchObj,
  }).toString();
  return axiosApiInstance.get(`${serverUrl}/getRequirementYouMayLike?${query}`);
};

export const useTopRequirement = (
  searchObj = {},
  getPaginationFromParams = true,
  enabled = true
) => {
  const pagination = usePagination(getPaginationFromParams);

  return useQuery({
    queryKey: ["topproduct", pagination, searchObj],
    queryFn: () =>
      getTopRequirement(pagination, searchObj).then((res) => res.data),
    enabled: enabled,
  });
};
export const getAllRequirementsBySupplierId = async (id) => {
  return axiosApiInstance.get(
    `${serverUrl}/getAllRequirementsBySupplierId/${id}`
  );
};

export const getRequirementById = async (id) => {
  return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};

export const updateRequirementApi = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

export const deleteRequirementbyId = async (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

// export const searchRequirement = async (query, cancelToken) => {
//   if (!cancelToken || !cancelToken?.token) {
//     return axios.get(`${serverUrl}/searchRequirementWithQuery?${query}`);
//   } else {
//     return axios.get(`${serverUrl}/searchRequirementWithQuery?${query}`, {
//       cancelToken: cancelToken?.token,
//     });
//   }
//   return axios.get(`${serverUrl}/searchRequirementWithQuery/?${query}`);
// };

import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { errorToast } from "./Utility/Toast";
import { phonepePaymentStatusCheck, verifyPayment } from "../services/UserSubscription.service";
function Payment() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [orderId, setOrderId] = useState("");
  const [orderIds, setOrderIds] = useState("");
  const [orderStatus, setOrderStatus] = useState(1);
  const [txn_id, settxn_id] = useState("");
  const [effective_amount, seteffective_amount] = useState("");
  const [txn_uuid, settxn_uuid] = useState("");
  const [txOrderStatus, setTxOrderStatus] = useState(false);
  const [errormsg, seterrormsg] = useState("")


  const searchParams = new URLSearchParams(location.search);

  const oserno = searchParams.get('orderId');
  const payment = searchParams.get('payment');
  useEffect(() => {
    setOrderId(params.id);

  console.log('hgtdf', params)
   
      setOrderId(params.id);
  }, [params, location.search])

  return (
    <>

        <main>
          <section className="ptb-50 order-complete">
            <div className="container">
              <div className="row text-center my-5">{
                orderStatus == 1 && (<div className="col-12 col-md-8 col-lg-6 mx-auto">
                  <h2>
                    Your Payment has been received
                    <span className="emoji" role="img">
                      🥳
                    </span>
                  </h2>
                  {/* <img src={images.party} alt="" className="mt-4 mb-5" /> */}
                  <h5>Thank you for your Payment!</h5>
                  <p className=" my-5">
                    Your Payment ID is&nbsp;:&nbsp;
                    <span className="green fw-semibold">{orderId}</span>
                  </p>
                  <p>
                    You will receive a booking confirmation email on registerd Email Id.
                  </p>

                </div>)
              }
                {orderStatus == 2 && (
                  <div className="col-12 col-md-8 col-lg-6 mx-auto">
                    <h3>Payment Failed: Technical Issue Encountered during Subscription Process</h3>
                    {/* <img src={images.party} alt="" className="mt-4 mb-5" /> */}
                    <h5>{`Please Contact Admin for Subscription Problem ${errormsg}`} </h5>
                    <h5>{`${errormsg}`} </h5>
                    <p className=" my-5">
                      Your Payment ID is &nbsp;:&nbsp;
                      <span className="green fw-semibold">{orderId}</span>
                    </p>


                  </div>)
                }

                {orderStatus == 0 && (
                  <div className="col-12 col-md-8 col-lg-6 mx-auto">
                    <h3>Please Wait</h3>




                  </div>)
                }
              </div>
            </div>
          </section>

        </main>


    </>
  );
}

export default Payment;
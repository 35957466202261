import usePagination from '@mui/material/usePagination/usePagination';
import { url } from './url.service'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query';

const serverUrl = `${url}/BannerImage`


export const getHomePageBannersApi = async (query) => {
  return axios.get(`${serverUrl}/getbanner?${query}`);
}
const getHomePageBanners = (
query
) => {
   return axios.get(`${serverUrl}/getbanner?${query}`
  );
};

export const useBanners = (
  query,
  enabled = true
) => {
  return useQuery({
    queryKey: ["bannners"],
    queryFn: () => getHomePageBanners(query).then((res) => res.data),
    enabled: enabled,
  });
};
import React from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import "./landing.css"; // Import the CSS file
import Footer from "../../components/Layout/Footer";

let landingPageData = {
  Header: {
    title: "We are a Landing Page",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sed commodo nibh ante facilisis bibendum.",
  },
  About: {
    paragraph:
      "Plywood bazar. com is India's largest online B2B market place brought a platform to interact with Manufacturers, Distributors, Dealers, Wholesalers and Retailers of Furniture, Plywood, Hardware & Interior Exterior Industries.",
    Why: [
      " Dealership/Franchise Opportunities",
      "Live Requirements Portal",
      "Flash Sales",
      "Business Reels",
    ],
    Why2: [
      "Banner Advertisement",
      "Lowest Price Option",
      "Verified Businesses",
      "Unlimited Connectivity",
    ],
  },
  Gallery: [
    {
      title: "Project Title",
      largeImage: "./img/portfolio/01-large.jpg",
      smallImage: "./img/portfolio/01-small.jpg",
    },
    {
      title: "Project Title",
      largeImage: "./img/portfolio/02-large.jpg",
      smallImage: "./img/portfolio/02-small.jpg",
    },
    {
      title: "Project Title",
      largeImage: "./img/portfolio/03-large.jpg",
      smallImage: "./img/portfolio/03-small.jpg",
    },
    {
      title: "Project Title",
      largeImage: "./img/portfolio/04-large.jpg",
      smallImage: "./img/portfolio/04-small.jpg",
    },
    {
      title: "Project Title",
      largeImage: "./img/portfolio/05-large.jpg",
      smallImage: "./img/portfolio/05-small.jpg",
    },
    {
      title: "Project Title",
      largeImage: "./img/portfolio/06-large.jpg",
      smallImage: "./img/portfolio/06-small.jpg",
    },
    {
      title: "Project Title",
      largeImage: "./img/portfolio/07-large.jpg",
      smallImage: "./img/portfolio/07-small.jpg",
    },
    {
      title: "Project Title",
      largeImage: "./img/portfolio/08-large.jpg",
      smallImage: "./img/portfolio/08-small.jpg",
    },
    {
      title: "Project Title",
      largeImage: "./img/portfolio/09-large.jpg",
      smallImage: "./img/portfolio/09-small.jpg",
    },
  ],
  Services: [
    {
      icon: "fa fa-wordpress",
      name: "Lorem ipsum dolor",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.",
    },
    {
      icon: "fa fa-cart-arrow-down",
      name: "Consectetur adipiscing",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.",
    },
    {
      icon: "fa fa-cloud-download",
      name: "Lorem ipsum dolor",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.",
    },
    {
      icon: "fa fa-language",
      name: "Consectetur adipiscing",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.",
    },
    {
      icon: "fa fa-plane",
      name: "Lorem ipsum dolor",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.",
    },
    {
      icon: "fa fa-pie-chart",
      name: "Consectetur adipiscing",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.",
    },
  ],
  Testimonials: [
    {
      img: "./img/testimonials/mahaveer_Sales.jpeg",
      text: '"In this dynamic era of business, I proudly serve as a distributor of hardware materials. Plywood Bazar.com has been instrumental in connecting me with reputable dealers, ensuring that I stay well-informed about the latest developments in our industry. I am consistently updated on the market landscape, allowing me to navigate with confidence and make informed decisions."',
      name: "Mahaveer Sales",
    },
    {
      img: "./img/testimonials/prageet.jpg",
      text: '"As a proud manufacturer, I showcase my products on PlywoodBazar.com, where numerous distributor dealers approach me. This platform allows me to effectively build a dealer network across every corner of India. I enjoy increased brand visibility nationwide at a remarkably low cost. Thanks to PlywoodBazar.com, my advertising budget is significantly reduced, making my business more efficient and impactful."',
      name: "New Pragati Plywood Industries",
    },
    {
      img: "./img/testimonials/pareek.jpg",
      text: '"As a distributor and dealer of plywood, I have access to a wide range of manufacturers at my fingertips. I posted my requirements and received multiple competitive quotations from suppliers in no time."',
      name: "Pareek Brothers",
    },
  ],
  Team: [
    {
      img: "./img/team/01.jpg",
      name: "John Doe",
      job: "Director",
    },
    {
      img: "./img/team/02.jpg",
      name: "Mike Doe",
      job: "Senior Designer",
    },
    {
      img: "./img/team/03.jpg",
      name: "Jane Doe",
      job: "Senior Designer",
    },
    {
      img: "./img/team/04.jpg",
      name: "Karen Doe",
      job: "Project Manager",
    },
  ],
  Contact: {
    address: "4321 California St, San Francisco, CA 12345 ",
    phone: "+1 123 456 1234",
    email: "info@company.com",
    facebook: "fb.com",
    twitter: "twitter.com",
    youtube: "youtube.com",
  },
  Features: [
    {
      icon: "fa fa-group",
      title: "Dealership/Franchise Opportunities",
      text: "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam.",
    },
    {
      icon: "fa fa-bullhorn",
      title: "Live Requirements Portal",
      text: "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam.",
    },
    {
      icon: "fa fa-comments-o ",
      title: "Flash Sales",
      text: "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam.",
    },
    {
      icon: "fa fa-magic",
      title: "Business Reels",
      text: "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam.",
    },
  ],
};
export const Landing = () => {
  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      {/* <Services data={landingPageData.Services} /> */}
      {/* <Gallery data={landingPageData.Gallery} /> */}
      <Testimonials data={landingPageData.Testimonials} />
      {/*<Team data={landingPageData.Team} /> 
      <Contact data={landingPageData.Contact} />*/}
      <Footer />
    </div>
  );
};

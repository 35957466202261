import React, { useState, useEffect, useRef, useContext } from "react";
import "./NewsHeadlineScroller.css"; // Import the CSS file
import { LuPhoneCall } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { getAllRequirements } from "../../services/Requirement.service";
import { errorToast } from "../Utility/Toast";
import { SignModalContext } from "../../context/SignModalContext";
import { useSelector } from "react-redux";
import { checkForValidSubscriptionAndReturnBoolean } from "../../services/User.service";
import { ROLES } from "../../utils/Roles.utils";
import { IoLocationSharp } from "react-icons/io5";
import { BiSolidCategory } from "react-icons/bi";
import { PiListNumbersFill } from "react-icons/pi";
const NewsHeadlineScroller = () => {
  const [isPaused, setIsPaused] = useState(false);
  const scrollContainerRef = useRef(null);
  const scrollContentRef = useRef(null);
  const [otpsent, setotpsent] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);

  const [requirement, setRequirement] = useState(null);
  const [RequirementArr, setRequirementArr] = useState([]);
  const navigate = useNavigate();
  const { isModalOpen, closeSignModal, openSignModal } =
    useContext(SignModalContext);
  const auth = useSelector((state) => state.auth.user);

  const [
    currentUserHasActiveSubscription,
    setCurrentUserHasActiveSubscription,
  ] = useState(false);
  useEffect(() => {
    if (auth && auth._id) {
      HandleCheckValidSubscription();
    }
  }, [auth]);
  const HandleCheckValidSubscription = async () => {
    try {
      let { data: res } = await checkForValidSubscriptionAndReturnBoolean(
        auth?._id
      );
      if (res.data) {
        setCurrentUserHasActiveSubscription(res.data);
      }
    } catch (err) {
      // toastError(err)
    }
  };
  useEffect(() => {
    handleGetRequirements(1, limit);
  }, []);

  const handleGetRequirements = async (skipValue, limitValue, searchQuery) => {
    try {
      let query = `page=${skipValue}&perPage=${limitValue}&website=true`;

      let { data: res } = await getAllRequirements(query);
      if (res.data) {
        setTotalElements(res.totalElements);
        setRequirementArr(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };
  // Logic to pause and resume scrolling on hover
  useEffect(() => {
    if (!scrollContentRef.current) return;

    if (isPaused) {
      // Pause animation by resetting the animation
      scrollContentRef.current.style.animationPlayState = "paused";
    } else {
      // Resume animation
      scrollContentRef.current.style.animationPlayState = "running";
    }
  }, [isPaused]);

  // Function to handle query on click
  const sendQuery = (product) => {
    if (!isAuthorized) {
      // If the user is not authorized, show the sign-in modal
      openSignModal();
      return 0;
    } else if (!currentUserHasActiveSubscription) {
      // If the user has an active subscription, close the modal
      closeSignModal();
      return 0;
    }
    let role = auth?.role;

    if (product.status == "DONE") {
      errorToast("This Deal is already Closed");
      return 0;
    }
    if (role == ROLES.CONTRACTOR || role == ROLES.RETAILER) {
      errorToast("You are not allowed to view this requirement");
      return 0;
    }
    console.log("Sending query for:", product);
    setRequirement(product);
    setotpsent(true);
    // Replace with your actual query logic (e.g., API call)
    // alert(`Sending query for ${product.name}`);
  };

  return (
    <>
      <div className="ticker-div">
        <div className="ticker-title">
          <span class="localize">Latest Deals</span>
          <button
            type="button"
            className="ticker-btn"
            onClick={() => navigate("/Requirement")}
          >
            Add Requirement
          </button>
          <span class="ticker-arrow"></span>
        </div>
        <div
          ref={scrollContainerRef}
          className="news-container"
          onMrouseEnter={() => setIsPaused(true)} // Pause scrolling on hover
          onMouseLeave={() => setIsPaused(false)} // Resume scrolling on mouse leave
        >
          <marquee className="news-content" ref={scrollContentRef}>
            <div class="d-flex">
              {RequirementArr &&
                RequirementArr.length > 0 &&
                RequirementArr.map((product, index) => (
                  <div
                    key={index}
                    onClick={() => sendQuery(product)}
                    className={`news-item `}
                  >
                    {/* Display the status icon before the status */}

                    <span className="other-labels">
                      <b>
                        {" "}
                        <BiSolidCategory />
                        {product.productName}
                      </b>
                    </span>
                    <span className="other-labels">
                      <b>
                        {" "}
                        <PiListNumbersFill /> Qty{" "}
                      </b>
                      {product.quantity}
                    </span>
                    <span className="other-labels">
                      <b>
                        <IoLocationSharp /> {product.cityName},{" "}
                        {product.stateName}
                      </b>
                    </span>
                    {product.status == "DONE" && (
                      <span className={`status-icon `}>Deal Done</span>
                    )}
                    {/*  <span
                          className="ticker-icon"> <LuPhoneCall  /></span>*/}
                  </div>
                ))}
            </div>
          </marquee>
        </div>
      </div>
      <Modal
        show={otpsent}
        centered
        onHide={() => setotpsent(false)}
        className="rounded-5"
      >
        <Modal.Body className="sign-in-modal custom-modal subscription-card-container rounded-5">
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={() => setotpsent(false)}
          ></button>
          <div>
            {/* <Link to="/" className="navbar-brand pt-3">
              <img src={images.logo} alt="" className="main-logo img-fluid" />
            </Link> */}
          </div>
          <h2 className="heading"> Requirement </h2>
          <form className="form row mt-4">
            <div className="row mt-4 col-12">
              <div className="col-6  ">
                <b>Product Name:</b>
              </div>
              <div className="col-6    ">{requirement?.productName}</div>
              <div className="col-6 ">
                <b>Quantity:</b>
              </div>
              <div className="col-6  ">{requirement?.quantity}</div>
              <div className="col-6 ">
                <b>State:</b>
              </div>
              <div className="col-6  ">{requirement?.stateName}</div>
              <div className="col-6 ">
                <b>City:</b>
              </div>
              <div className="col-6  ">{requirement?.cityName}</div>
              <div className="col-12 my-1">
                <p className="description">{requirement?.description}</p>
              </div>
              <div className="col-12 text-center tickerviewlbtn  d-flex gap-2  justify-content-center">
                <span className="ticker-icon">
                  <LuPhoneCall
                    color="#fff"
                    onClick={() => {
                      if (!isAuthorized) {
                        // If the user is not authorized, show the sign-in modal
                        openSignModal();
                      } else if (!currentUserHasActiveSubscription) {
                        // If the user has an active subscription, close the modal
                        closeSignModal();
                      } else {
                        // handleContactSupplier(el.productId);
                        // If the user has an active subscription, initiate a phone call
                        window.location.href = `tel:${requirement?.phone}`;
                      }
                    }}
                  />
                </span>
                <button
                  type="button"
                  onClick={() => {
                    if (!isAuthorized) {
                      // If the user is not authorized, show the sign-in modal
                      openSignModal();
                    } else if (!currentUserHasActiveSubscription) {
                      // If the user has an active subscription, close the modal
                      closeSignModal();
                    } else {
                      // If the user does not have an active subscription, show the price modal
                      navigate(`Supplier/${requirement?.userId}`);
                    }
                  }}
                >
                  View Profile
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewsHeadlineScroller;

import axios from "axios";
import { url } from "./url.service";
import usePagination from "@mui/material/usePagination/usePagination";
import { useQuery } from "@tanstack/react-query";

let serverUrl = `${url}/category`;

const getCategory = (
    pagination,
    searchObj
  ) => {
    const query = new URLSearchParams({
      pageIndex: String(pagination.pageIndex),
      pageSize: String(pagination.pageSize),
      ...searchObj,
    }).toString();
    return axios.get(`${serverUrl}/getCategory/?${query}`)
  };
  
  export const useCategories = (
    searchObj = {},
    getPaginationFromParams = true,
    enabled = true
  ) => {
    const pagination = usePagination(getPaginationFromParams);
    return useQuery({
      queryKey: ["categories", pagination, searchObj],
      queryFn: () => getCategory(pagination, searchObj).then((res) => res.data),
      enabled: enabled,
    });
  };


export const getAllCategories = async (query) => {
    return axios.get(`${serverUrl}/getCategory/?${query}`)
}


export const getNestedCategories = async (query) => {
    return axios.get(`${serverUrl}/getNestedCategories/?${query}`)
}
export const getChildCategory = async (id) => {
    return axios.get(`${serverUrl}/getChildCategory/${id}`)
}


export const getNameById = async (id) => {
    return axios.get(`${serverUrl}/getNameById/${id}`)
}

export const getNameBySlug = async (id, query = "") => {
    return axios.get(`${serverUrl}/getNameBySlug/${id}?${query}`)
}




import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  deleteFlashSalebyId,
  getAllFlashSalesbyUserId,
} from "../../services/FlashSales.service";
import {
  getLikeCount,
  postReelView,
  deleteBlogVideoApi,
  useBlogVideo,
  useMyBlogVideo,
} from "../../services/ReelVideo.service";

import { getAllsubscription } from "../../services/Subscription.service";
import { buySubscription } from "../../services/UserSubscription.service";
import { errorToast, successToast } from "../Utility/Toast";
import { FaEdit, FaTrash, FaPencilAlt } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { toastSuccess } from "../../utils/toastutill";
import { getUserById } from "../../services/User.service";
import {
  deleteAdvertisement,
  getAllAdvertisements,
} from "../../services/Advertisement.service";
import { generateImageUrl } from "../../services/url.service";
import "../../assets/css/Topup.css";
export default function MyReel() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  let id = useSelector((state) => state.auth.user._id);

  const { data: videos, refetch } = useMyBlogVideo(
    id
  );

  let userObj = useSelector((state) => state.auth.user);
  const [userSubscriptionExpired, setUserSubscriptionExpired] = useState(true);
  const [userDataObj, setUserDataObj] = useState({});
  const [advertisementArr, setAdvertisementArr] = useState([]);

  const handleDeleteFlashSale = async (id) => {
    try {
      let { data: res } = await deleteBlogVideoApi(id);
      if (res.message) {
        toastSuccess(res.message);
        refetch();
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const handleRedirectToEditScreen = async (id) => {
    navigate(`/AddReel?id=${id}`);
  };

  return (
    <div className="container-fluid subscription-container topup-subscription-container">
      <div className="container">
        <div className="subsctiption-heading">
          My Reel &nbsp;&nbsp;
          <Link
            to="/AddReel"
            className="yellow-bg btn text-white subsctiption-card-button  rounded-circle"
          >
            <FaPlus />
          </Link>
        </div>
        {/*<div className="subscription-description">
                    Buy our topup to get a steady flow of leads for your business and take your business to the next level.
                </div>
*/}{" "}
        <div className="row">
          <div className="col-12">
            <div className="row">
              {videos?.data &&
                videos?.data.length > 0 &&
                videos?.data.map((el, index) => {
                  return (
                    <div key={index} className="subscription-card pb-3">
                      <div className="row ">
                        <div className=" d-flex justify-content-end my-2">
                          <button
                            onClick={() => handleDeleteFlashSale(el?._id)}
                            className="yellow-bg btn text-white mx-2   rounded-5"
                          >
                            {" "}
                            <FaTrash />
                          </button>

                          <button
                            onClick={() => handleRedirectToEditScreen(el?._id)}
                            className="yellow-bg btn text-white  mx-2  rounded-5"
                          >
                            <FaPencilAlt />
                          </button>
                        </div>
                        <div className="col-12">
                          <video
                            style={{
                              width: 200,
                              height: 200,
                            }}
                            src={generateImageUrl(el.video)}
                          />
                           <div className="row ">
                            <div className="col-6 ">Status</div>
                            <div className="col-6  ">{el.status ?"Approved" :"Pending" }</div>
                          </div>

                          <div className="row ">
                            <div className="col-6 ">Likes</div>
                            <div className="col-6  ">{el.likes}</div>
                          </div>
                          <div className="row ">
                            <div className="col-6 ">Views</div>
                            <div className="col-6  ">
                              {el.views ? el.views : 0}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12  my-1 clr">
                              {el?.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className='container-fluid subscription-container'>
    //     <div className="container">
    //         <div className="row me-2 d-flex justify-content-between">
    //             <div className="col-6 col-sm-7 subsctiption-heading">
    //                 Your Promotions
    //             </div>
    //             <div className="col-6 col-sm-5 mt-5 justify-content-end">
    //                 {
    //                     (userSubscriptionExpired == false && userDataObj?.numberOfAdvertisement > 0 && !userDataObj?.isBlocked) ?
    //                         <div className="row d-flex justify-content-end">
    //                             <button className="theme-outline-button" onClick={() => navigate("/AddPromotions")}>
    //                                 Create an advertisement
    //                             </button>
    //                         </div>
    //                         :
    //                         (!userSubscriptionExpired && (!userDataObj?.numberOfAdvertisement || userDataObj?.numberOfAdvertisement <= 0) && !userDataObj?.isBlocked) &&
    //                         <div className="row d-flex justify-content-end">
    //                             <button className="theme-outline-button" onClick={() => navigate("/Subscription")}>
    //                                 Purchase a subscription
    //                             </button>
    //                         </div>
    //                 }
    //             </div>
    //         </div>
    //         {
    //             (userSubscriptionExpired) &&
    //             <div className="subscription-description">
    //                 You do not have a valid subscription , subscribe one to add an advertisement
    //             </div>
    //         }
    //         {
    //             (userDataObj?.numberOfAdvertisement <= 0) &&
    //             <div className="subscription-description">
    //                 You do not have a enough balance in your account , subscribe one to add an advertisement
    //             </div>
    //         }
    //         {
    //             (userDataObj?.isBlocked) &&
    //             <div className="subscription-description">
    //                 Your subscription has been blocked by admin please contact admin for further details
    //             </div>
    //         }
    //         <div className="row">
    //             <div className="col-12">
    //                 <div className="row d-flex justify-content-between">

    //                     {
    //                         advertisementArr && advertisementArr.length > 0 && advertisementArr.map((el, index) => {
    //                             return (

    //                                 <div className="col-lg-4 col-sm-12 col-md-6 mb-3">
    //                                 <div key={index} className='profile-section-container '>
    //                                 <span>width:356px and height:250px</span>
    //                                     <div className="row">
    //                                         <div className="col-3">
    //                                             {/* <h4><b>{el?.userObj?.name}</b></h4> */}

    //                                         </div>
    //                                         <div className="col-9 d-flex justify-content-end">
    //                                             <div className="theme-outline-button" onClick={() => handleRedirectToEditScreen(el._id)} style={{ fontSize: 12, padding: "4px 10px" }}>
    //                                                 <FaEdit />
    //                                                 {/* Created On -  {moment(el?.createdAt).format("DD-MM-YYYY")} */}
    //                                             </div>
    //                                             <div className="theme-outline-button ms-2" onClick={() => handleDeleteFlashSale(el._id)} style={{ fontSize: 12, padding: "4px 10px" }}>
    //                                                 <FaTrash />
    //                                                 {/* Created On -  {moment(el?.createdAt).format("DD-MM-YYYY")} */}
    //                                             </div>
    //                                         </div>
    //                                     </div>

    //                                     <div className="row mt-4">
    //                                         <div className="col-12">

    //                                             {
    //                                                 el.isVideo ?
    //                                                     <video style={{ width: "100%", height: 200 }} src={generateImageUrl(el.image)} />
    //                                                     :
    //                                                     <img style={{ width: "100%", height: 200 }} src={generateImageUrl(el.image)} alt="" />
    //                                             }

    //                                         </div>
    //                                         <div className="col-5 my-1">

    //                                             Product Name:
    //                                         </div>
    //                                         <div className="col-7  my-1">
    //                                             {el?.productId.name}
    //                                         </div>
    //                                         <div className="col-5 my-1">
    //                                             Message:
    //                                         </div>
    //                                         <div className="col-7  my-1">
    //                                             {el?.message}
    //                                         </div>
    //                                         <div className="col-5 my-1">
    //                                             Start Date:
    //                                         </div>
    //                                         <div className="col-7  my-1">
    //                                             {moment(el?.startDate).format("DD-MM-YYYY")}
    //                                         </div>
    //                                         <div className="col-5 my-1">
    //                                             End Date:
    //                                         </div>
    //                                         <div className="col-7  my-1">
    //                                             {moment(el?.endDate).format("DD-MM-YYYY")}
    //                                         </div>
    //                                     </div>

    //                                 </div>
    //                                 </div>
    //                             )
    //                         })
    //                     }
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div >
  );
}

import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import Video from "./ReelVideoNew";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { getBlogVideoApi } from "../services/ReelVideo.service";


const VideoListStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  /* Make the entire screen scrollable */
  overflow-y: auto; /* Allows scrolling for the whole screen */

  /* Make the header fixed at the top */
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px; /* Adjust based on your header height */
    background-color: #fff; /* Add styling for the header */
    z-index: 10;
  }

  /* Make the footer fixed at the bottom */
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px; /* Adjust based on your footer height */
    background-color: #fff; /* Add styling for the footer */
    z-index: 10;
  }

  /* Main content container that takes space between the header and footer */
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 60px; /* Offset for the fixed header */
    margin-bottom: 60px; /* Offset for the fixed footer */
    padding: 1rem;

    /* Ensure this area is scrollable if there is content overflow */
    overflow-y: auto;
  }

  .video-list {
    display: grid;
    place-items: center;
    gap: 1rem;
    scroll-snap-type: y mandatory;
    padding: 1rem;
    max-width: 100%; /* Ensure it's not too wide */

    /* Hide scrollbar for webkit browsers */
    ::-webkit-scrollbar {
      display: none;
    }

    /* For Firefox */
    scrollbar-width: none;
  }

  /* Styling for the loader or end message */
  .loader {
    text-align: center;
    padding: 1rem;
  }
`;

const VideoList = () => {
  const [videos, setVideos] = useState([]);
  const [page, setPage] = useState(0);
  const [mute, setMute] = useState(true);

  const getVideos = async (currentPage) => {
    try {
      const res = await getBlogVideoApi(`page=${page + 1}&perPage=2&status=true`);
      setVideos((prevVideos) => {
        const filteredVideos = res.data.data.filter(
          (video) => !prevVideos.some((v) => v._id === video._id)
        );
        return [...prevVideos, ...filteredVideos];
      });
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    getVideos(page);
  }, [page]);

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <VideoListStyled>
      <header>
        {/* Add your header content here */}
        <h1>Header Content</h1>
      </header>

      <div className="content">
        <InfiniteScroll
          dataLength={videos.length}
          next={handleNextPage}
          hasMore={true}
          loader={<span className="loader">Loading...</span>}
          endMessage={<p className="end-message">You have reached the end!</p>}
          className="video-list"
        >
          {videos.length ? (
            videos.map((video) => (
              <Video
                key={video._id}
                video={video}
                mute={mute}
                setMute={setMute}
              />
            ))
          ) : (
            <span className="loader">Loading...</span>
          )}
        </InfiniteScroll>
      </div>

      
    </VideoListStyled>
  );
};

export default VideoList;

import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

let serverUrl = `${url}/usersubscription`;


export const buySubscription = async (obj) => {
		let tempobj = {
		...obj,
		SourceName:'WEB'
	}
    return axiosApiInstance.post(`${serverUrl}/buySubscription`, tempobj)
}
export const buySubscriptionforhdfc = async (obj) => {
	let tempobj = {
		...obj,
		SourceName:'WEB'
	}

    return axiosApiInstance.post(`${serverUrl}/initiateJuspayPaymentForSubcription`, tempobj)
}
export const verifyPayment = async (obj) => {
    return axiosApiInstance.post(`${url}/payments/verifyPayment`, obj)
}
// export const buySubscription = async (obj) => {
//     return axiosApiInstance.post(`${serverUrl}/initiateJuspayPaymentForSubcription`, obj)
// }
export const getAllSubscriptionbyUserId = async (query) => {
    return axiosApiInstance.get(`${serverUrl}/getAllSubscriptionbyUserId?${query}`)
}


export const phonepePaymentStatusCheck = async (id) => {
    return axios.get(`${serverUrl}/phonepePaymentStatusCheck/${id}`)
}


export const usersubscriptionMailId = async (id) => {
    return axios.get(`${serverUrl}/sendMailById/${id}`)
}


import React, { useState, useEffect, useRef, useContext } from "react";
import { getBlogVideoBySlugApi, useBlogVideo, postReelView, getLikeCount, toggleLIked } from "../services/ReelVideo.service";
import { toastError } from "../utils/toastutill";
import { useSelector } from "react-redux";
import { SignModalContext } from "../context/SignModalContext";
import { useParams } from "react-router-dom";
import Video from "./ReelVideoNew";
import "./reels.css";

const Reels = () => {
  const [reels, setReels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { data: videos,refetch } = useBlogVideo({ page, perPage: 2, status: true });

  const auth = useSelector((state) => state.auth.user);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const { openSignModal } = useContext(SignModalContext);
  const [totalPages, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [mute, setMute] = useState(true);
  const [playState, setPlayState] = useState(true); // Keep track of which video is playing

  const scrollContainerRef = useRef(null);
  const videoRefs = useRef({}); // Store references for each video

  const [blogDetails, setBlogDetails] = useState({});
  const params = useParams();

  // Fetch blog details based on slug
  const handleGetBlogDetail = async () => {
    try {
      let { data: res } = await getBlogVideoBySlugApi(params.id);
      if (res.data) {
        setBlogDetails(res.data);
      }
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    handleGetBlogDetail();
  }, [params]);

  // Fetch more reels when scrolling
  const fetchReels = async () => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    setPage((prevPage) => prevPage + 1);
  };

  // Handle view counts
  const handlewView = async (videoId) => {
    try {
      let { data: res } = await postReelView({ reelId: videoId });
    } catch (error) {
      toastError(error);
    }
  };

  // Detect when the user reaches the bottom of the scrollable div
  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;
    const isAtBottom =
      container.scrollHeight === container.scrollTop + container.clientHeight;
    if (isAtBottom && !isLoading) {
    fetchReels()
     
    }
  };

  useEffect(() => {
    if (videos?.data) {
      console.log(videos?.data,"dddddddd")
      setTotal(videos?.BlogVideoCount);
      if (videos.data.length > 0) {
        setReels((prevReels) => [...prevReels, ...videos.data]);
        if (page*2 >= videos.BlogVideoCount) {
          setHasMore(false);
        }
      }
    }
    setIsLoading(false);
  }, [videos]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      const container = scrollContainerRef.current;
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isLoading, hasMore]);

  // Handle play/pause toggle for each video
  const handlePlayPause = (videoId) => {
    const videoElement = videoRefs.current[videoId];
    // Pause all videos if any other video is playing
    Object.keys(videoRefs.current).forEach((id) => {
      if (id !== videoId && playState[id]) {
        videoRefs.current[id].pause(); // Pause other videos
        setPlayState((prev) => ({ ...prev, [id]: false }));
      }
    });

    // Toggle play/pause for the current video
    if (videoElement.paused) {
      videoElement.play();
      handlewView(videoId);
      setPlayState((prev) => ({ ...prev, [videoId]: true }));
    } else {
      videoElement.pause();
      setPlayState((prev) => ({ ...prev, [videoId]: false }));
    }
  };

  return (
    <>
      <div
        className="scroll-container"
        ref={scrollContainerRef}
      >
        <ReelVideo reel={blogDetails} /> {/* Render the blog video first */}
        {reels.map(
          (reel) =>
            reel && reel._id !== blogDetails?._id && (
              <Video
                key={reel._id}
                video={reel}
                mute={mute}
                setMute={setMute}
                playingVideo={playState}
                setPlayingVideo={setPlayState}
                videoRefs={videoRefs}
                handlePlayPause={handlePlayPause}
              />
            )
        )}
        {isLoading && <div className="loader">Loading...</div>}
      </div>
    </>
  );
};




const ReelVideo = ({ reel }) => {
  const [likesCount, setLikesCount] = useState(0);
  const [isLiked, setIsLiked] = useState(0);
  const videoRef = useRef(null); // Unique reference for each video
  const auth = useSelector((state) => state.auth.user);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const { openSignModal } = useContext(SignModalContext);
  const [playState, setPlayState] = useState(true); // Keep track of which video is playing
  const [mute, setMute] = useState(true);

  const handlewView = async (videoId) => {
    try {
      let { data: res } = await postReelView({ reelId: videoId });
    } catch (error) {
      toastError(error);
    }
  };

  const handlePlayPause = () => {
    const videoElement = videoRef.current;
    setPlayState(!playState);
    if (videoElement.paused) {
      videoElement.play();
      handlewView(reel?._id);
      // Play the current video
    } else {
      videoElement.pause(); // Pause the current video
    }
  };
  useEffect(() => {
    // fetchData();
  }, [reel]);

  const handleShare = async (reel) => {
    const shareData = {
      title: reel.user,
      text: `Check out this reel by ${reel.title}!`,
      url: "https://plywoodbazar.com/reel-detail/" + reel?._id,
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
        alert("Shared successfully!");
      } else {
        alert("Sharing is not supported in this browser.");
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };
  // Handle like functionality
  const handleLike = async () => {
    if (!isAuthorized) {
      openSignModal();
      return;
    }
    try {
      await toggleLIked({ reelId: reel?._id, userId: auth?._id });
      setIsLiked(!isLiked);
      setLikesCount((prev) => (isLiked ? prev - 1 : prev + 1));
    } catch (error) {
      toastError(error);
    }
  };

  // Fetch initial like count and user’s like status
  const fetchData = async () => {
    const { data: res } = await getLikeCount({
      reelId: reel?._id,
      userId: auth?._id,
    });
    if(res.data){
       setLikesCount(res?.data?.likeCount);
    setIsLiked(res?.data?.liked);
    }
   
  };

  return (
       <Video
                key={reel._id}
                video={reel}
                mute={mute}
                setMute={setMute}
                playingVideo={playState}
                setPlayingVideo={setPlayState}
              />
  );
};

export default Reels;

import React, { useContext, useState,useEffect } from 'react'
import { SignModalContext } from '../../context/SignModalContext';
import Modal from "react-bootstrap/Modal";
import { Link } from 'react-router-dom';
import { images } from "./../Utility/Images";
import { errorToast, successToast } from '../Utility/Toast';
import { sentOtp } from '../../services/User.service';
import { useSelector, useDispatch } from "react-redux";
import { login } from '../../redux/features/auth/authSlice';


const PopUp = () => {

  const { openSignModal, closeSignModal } = useContext(SignModalContext);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);

  const [otpsent, setotpsent] = useState(false);
  

  const handlepoup = () => {
  		let isPopUp = window.sessionStorage.getItem("ispoup");
  	if(!isPopUp && !isAuthorized){
  			setotpsent(true);;
  			window.sessionStorage.setItem("ispoup","true")
  	} 
  }

  useEffect(()=>{
  	setTimeout(handlepoup,2000)
  },[])

  if (!otpsent) return null;

  return (
    <Modal
    show={otpsent}
    centered
    onHide={() => setotpsent(false)}
    className="rounded-5"
  >
    <Modal.Body className="sign-in-modal custom-modal subscription-card-container rounded-5">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        onClick={() => setotpsent(false)}
      ></button>
      <div>
        <Link to="/" className="navbar-brand pt-3">
          <img src={images.logo} alt="" className="main-logo img-fluid" />
        </Link>
      </div>
      {/*<h2 className="heading">Register with Us.</h2>*/}
      <form className="form row mt-4">
     

        <div className="col-12 mb-0">
         
            <p className="">Register with Us. 
              <Link
            to="/Register"
            className="btn btn-custom ms-2 "

            style={{border: '1px solid',
              background: '#eee'}}
          >
            Register 
          </Link></p>
        </div> 
       
        <div className="col-12 mt-0 ">

            <p className="">If Already User.   <button
              type="button"
              onClick={() => {
                 setotpsent(false);
                 openSignModal();
              }}
              className="btn btn-custom  ms-2"
               style={{border: '1px solid',
              background: '#eee'}}
            >
              Login In
            </button></p>
              </div>
            
     
      </form>
    </Modal.Body>
  </Modal>
  )
}

export default PopUp
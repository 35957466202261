import React, { useEffect, useState } from "react";
import { getAllsubscription } from "../services/Subscription.service";
import { buySubscription } from "../services/UserSubscription.service";
import { errorToast, successToast } from "./Utility/Toast";
import { useSelector } from "react-redux";
import { getAlltopups } from "../services/topup.service";
import { buyTopup } from "../services/UserTopup.service";
import "../assets/css/Topup.css";
import Modal from "react-bootstrap/Modal";
import phonepae from "../assets/image/home/phonepae.png";
import hdfc from "../assets/image/home/hdfc.jpg";
import { toastError } from "../utils/toastutill";

export default function Topup() {
  const [loading, setLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [topupArr, setSubscriptionArr] = useState([]);
  const [subscriptionObj, setSubscriptionObj] = useState();
  const [total, setTotal] = useState(0);
  const [priceArr, setPriceArr] = useState([]);


  let role = useSelector((state) => state.auth.role);

  const handleGetSubscriptions = async () => {
    try {
      let { data: res } = await getAlltopups(`role=${role}`);
      if (res.data) {
        setSubscriptionArr(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };

const handleSelect = (el) => {
  let tempArr = [...priceArr];
  // let priceObjInde = tempArr.findIndex((elx) => elx.name == el.name);
 let priceObjInde=  priceArr.findIndex(item => item.name === el.name && el.type == item.type && el.numberOfDays == item.numberOfDays);
  console.log(priceObjInde,"priceObjInde",el)
  if(priceObjInde !=-1){
    tempArr[priceObjInde].price =  el.price;
    tempArr[priceObjInde].type =  el.type;
    tempArr[priceObjInde].numberOfDays =  el.numberOfDays;
  } else {
    tempArr.push(el);
  }
console.log(tempArr,"totolPrice")
 let totolPrice = tempArr.reduce((acc,cur) => acc+cur.price,0);

 console.log(totolPrice)
 setTotal(totolPrice);
 setPriceArr([...tempArr])
}

  useEffect(() => {
    handleGetSubscriptions();
  }, []);
  const handlePlayment = () => {
    if(priceArr?.length == 0){  
        toastError("Please Select One of the Topup");
        return 0
    }
    // setSubscriptionObj(subscription);
    setPaymentModal(true);
  };

  const handleBuyTopup = async (paymentType) => {
    try {
      let obj = {
        priceArr,
        total,
        paymentType,
      };
      let { data: res } = await buyTopup(obj);
      setLoading(true);
      if (res.success) {
        // successToast(res.message);
        if (paymentType == "phonepay") {
          if (res?.data && res?.data.instrumentResponse) {
            let instrumentResponse = res?.data.instrumentResponse;
            if (instrumentResponse?.redirectInfo) {
              window.location.href = instrumentResponse?.redirectInfo.url;
              return 0;
            }
            setLoading(false);
          } else {
            setLoading(false);

            errorToast(
              "`Phonepe is not working.Please Try Some another Payment Method"
            );
          }
        } else if (paymentType == "justpay") {
          if (res && res.payment_links) {
            let payment_links = res.payment_links;

            // Redirect to payment link if available
            if (payment_links.web) {
              setLoading(false); // Hide loading before redirect
              window.location.href = payment_links.web;
              return 0;
            }
            setLoading(false);
          } else {
            setLoading(false);

            errorToast(
              "Payment gateway is not working. Please try another payment method."
            );
          }
        }
        setLoading(false);

        return 0;
      }
    } catch (err) {
      console.log(err);
      errorToast(err);
    }
  };

const handleClear = () => {
  setPriceArr([])
    Array.from( document.querySelectorAll('input[type="radio"]:checked'), input => input.checked = false );
  setTotal()
}
  return (
    <div className="container-fluid subscription-container topup-subscription-container">
      <div className="container">
        <div className="d-flex justify-content-between">
          <div>
            <div className="subsctiption-heading ">Our topup plans</div>
            <div className="subscription-description">
              Buy our topup to get a steady flow of leads for your business and
              take your business to the next level.
            </div>
          </div>
          {
            total > 0 && (
              <div>
              <p className="subsctiption-heading mb-0">₹{total}</p>
              <p className="subscription-description mb-2
              " >(+18% GST)</p>
              <button className="yellow-bg btn text-white subsctiption-card-button mb-2" onClick={()=>handlePlayment()}>
                Pay Now
              </button>
               <button className="yellow-bg btn text-white subsctiption-card-button ms-sm-2 mb-2" onClick={()=>handleClear()}>
                Reset

              </button>
            </div>
            )
          }
        
        </div>

        <div className="row">
          <div className="col-12">
            <div className="row">
              {topupArr &&
                topupArr.length > 0 &&
                topupArr.map((el, index) => {
                  return (
                    <div key={index} className="subscription-card">
                      <div className="subscription-card-heading">
                        {el?.name}
                      </div>

                      {el.priceArr && el.priceArr.length > 0 && (
                        <ul className="subscription-card-message-list">
                          {el.priceArr.map((ele, indexX) => (
                            <li
                              className="d-flex justify-content-between"
                              key={indexX}
                              htmlFor={el._id}
                            >
                              ₹{ele?.price} For{" "}
                              {ele?.numberOfDays > 1
                                ? `${ele?.numberOfDays} Days`
                                : `${ele?.numberOfDays} Day`}
                              <input
                                type="radio"
                                name={el.type}
                                value={ele._id}
                                className="me-4"
                                id={el._id}
                                onChange={()=>handleSelect({
                                  price:ele.price,
                                  numberOfDays:ele.numberOfDays,
                                  name:el.name,
                                  type:el.type,
                                })}
                              />
                            </li>
                          ))}
                        </ul>
                      )}
                      {el.description != "" && (
                        <div className="subscription-card-days mt-4">
                          {el?.description}
                        </div>
                      )}

                      {el.messageArr && el.messageArr.length > 0 && (
                        <ul className="subscription-card-message-list">
                          {el.messageArr.map((ele, indexX) => (
                            <li key={indexX}>{ele?.message}</li>
                          ))}
                        </ul>
                      )}

                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={paymentModal}
        centered
        onHide={() => setPaymentModal(true)}
        className="rounded-5"
      >
        <Modal.Body className="sign-in-modal custom-modal subscription-card-container rounded-5">
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={() => setPaymentModal(false)}
          ></button>

          <h2 className="heading">Pay With</h2>
          <form className="form row">
            {loading ? (
              "Please Wait..."
            ) : (
              <div className="col-12">
                <button
                  type="button"
                  onClick={() => handleBuyTopup("phonepay")}
                  className="btn btn-custom text-white border-dark py-2 w-100"
                >
                  <img src={phonepae} className=" img-fluid" />
                </button>
                <button
                  type="button"
                  onClick={() => handleBuyTopup("justpay")}
                  className="btn btn-custom text-white  py-2 w-100 mt-3 border-dark"
                >
                  <img src={hdfc} className=" img-fluid" width="50%" />
                </button>
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

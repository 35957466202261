import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  getCityByStateApi,
  getCountriesApi,
  getStateByCountryApi,
  useCities,
  useCountry,
  useStates,
} from "../../services/location.service";
import { ROLES_CONSTANT } from ".././Utility/constant";
import { getAllProducts } from "../../services/Product.service";
import Select from "react-select";

import { AiFillCheckCircle, AiOutlineInfoCircle } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import {
  getCroppedImg,
  handleOpenImageInNewTab,
} from "../../utils/image.utils";
import Cropper from "react-easy-crop";
import FileInput from "../Utility/FileUploadCropper";
import { convertFileToBase64 } from ".././Utility/FileConverterToBase64";
import {
  getRequirementById,
  updateRequirementApi,
  AddRequirementApi,
} from "../../services/Requirement.service";
import { addBrandApi, getBrandApi } from "../../services/brand.service";
import { errorToast, successToast } from "../Utility/Toast";
import {
  getAllCategories,
  useCategories,
} from "../../services/Category.service";
import { getUserById } from "../../services/User.service";

const AddRequirement = () => {
  const navigate = useNavigate();

  const [quantity, setQuantity] = useState("");

  let role = useSelector((state) => state.auth.role);

  const [productArr, setProductArr] = useState([]);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const [searchParams, setSearchParams] = useSearchParams();
  const [requirementId, setRequirementId] = useState("");
  console.log("searchParams", searchParams.get("RequirementId"));
  useEffect(() => {
    setRequirementId(searchParams.get("RequirementId"));
  }, [searchParams]);

  // const requirementId = "66f5301c2b94608ae86221b3";  // Extract id from the URL
  const userObj = useSelector((state) => state.auth.user);
  console.log("requirementId........................", requirementId, userObj);

  const [stateId, setStateId] = useState("");
  const [show, setShow] = useState(false);
  const [productName, setProductName] = useState(""); // Sample Product ID
  const [description, setDescription] = useState(""); // Sample Product ID
  const [cityId, setCityId] = useState([]); // Array of city IDs
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dealerObj, setDealerObj] = React.useState(false);

  const handleClose = () => setShow(false);
  const { data: states } = useStates({}, false, true);
  const { data: cities } = useCities({ stateId }, false, !!stateId);

  useEffect(() => {
    if (requirementId) {
      fetchRequirementDetails(requirementId);
    }
  }, [requirementId]); // Fetch when requirementId changes

  useEffect(() => {
    if (cities?.data && dealerObj.cityId) {
      let citArrr = cities?.data?.filter((el) =>
        dealerObj?.cityId.includes(el._id)
      );
      setCityId(citArrr.map((city) => ({ value: city._id, label: city.name })));
    }
  }, [dealerObj, cities]);

  const fetchRequirementDetails = async (id) => {
    try {
      const { data: dealership } = await getRequirementById(id);

      if (dealership?.data) {
        setProductName(dealership?.data?.productName);
        setQuantity(dealership?.data?.quantity);
        setStateId(dealership?.data?.stateId);
        setCityId(dealership?.data?.cityId);
        setDescription(dealership?.data?.description);
      }
    } catch (error) {
      console.error("Error fetching dealership details", error);
    }
  };
  // Fetch dealership details if editing (if requirementId exists)

  const handleSubmit = async (e) => {
    if (`${productName}` === "") {
      errorToast("Please Enter Your Product Name");
      return 0;
    }
    if (`${description}` === "") {
      errorToast("Please Enter Your Requirment Description");
      return 0;
    }

    if (`${stateId}` === "") {
      errorToast("Please Select State");
      return 0;
    }

    if (`${quantity}` === "") {
      errorToast("Please Enter Your Quantity");
      return 0;
    }

    if (!cityId || cityId === "") {
      errorToast("Please Select City");
      return 0;
    }

    let stateObj = states?.data?.find((el) => el._id === stateId);
    let cityObj = cities?.data?.find((el) => el._id === cityId);

    const formData = {
      userId: userObj._id,
      productName,
      cityId,
      stateId: stateId,
      stateName: stateObj?.name,
      cityName: cityObj?.name,
      description,
      quantity,
      status: "APPROVED",
    };

    try {
      if (requirementId) {
        // Update existing dealership
        setLoading(true);

        const { data: response } = await updateRequirementApi(
          formData,
          requirementId
        );
        setLoading(false);

        successToast("Requirement updated successfully");
      } else {
        // Add new dealership
        const { data: response } = await AddRequirementApi(formData);
        setLoading(false);

        successToast("Requirement added successfully");
      }
      navigate("/Requirement"); // Redirect after successful submission
    } catch (error) {
      setLoading(false);
      console.error("Error submitting form", error);
    }
  };

  const handleCloseAndNavigate = () => {
    setShow(false);
    navigate("/?loginTriggered=true");
  };

  return (
    <>
      <div className="register_user topup-subscription-container">
        <div className="container">
          <div className="row m-3 pt-3">
            <div className="col-12 col-md-12">
              <div className="right">
                <h3 className="heading yellow">
                  {requirementId ? "Edit Requirement" : "Add Requirement"}
                </h3>

                <form className="form row">
                  <div className="row">
                    <div className="col-md-6">
                      <label>
                        Product <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Product Name"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>
                        State <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={stateId}
                        onChange={(e) => {
                          setStateId(e.target.value);
                          setCityId([]);
                        }}
                      >
                        <option value="">Select State</option>
                        {states?.data.map((state) => (
                          <option key={state._id} value={state._id}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label>
                        City <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={cityId}
                        onChange={(e) => {
                          setCityId(e.target.value);
                        }}
                      >
                        <option value="">Select City</option>
                        {cities?.data.map((city) => (
                          <option key={city._id} value={city._id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label>
                        Quantity <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter your Quantity"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <label>
                        Description <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="number"
                        className="form-control"
                        placeholder="Enter your Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-12 mt-3 mb-3">
                    {loading ? (
                      <button
                        type="button"
                        className="btn btn-custom btn-yellow mt-5"
                      >
                        Please Wait...
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          handleSubmit();
                        }}
                        className="btn btn-custom btn-yellow mt-5"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRequirement;

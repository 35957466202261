import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BiChevronDown } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteRequirementbyId,
  getAllRequirements,
  updateRequirementApi,
} from "../../services/Requirement.service";
import { errorToast, successToast } from "../Utility/Toast";
import { toastError, toastSuccess } from "../../utils/toastutill";
import ReactPaginate from "react-paginate";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import "../../assets/css/Products.css";
import { IoSearch } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
};
export default function Requirement() {
  const windowWidth = useWindowWidth();

  let userObj = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const [totalElements, setTotalElements] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [RequirementArr, setRequirementArr] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const customStyles = {
    tableWrapper: {
      style: {
        // borderRadius: '10px',
        // boxShadow: '15px 18px 35px 0px #00000040',
        // border: '1px solid #c4b7b7',
        // textAlign: 'center',
        // overflow: 'hidden',
      },
    },
    headCells: {
      style: {
        backgroundColor: "#bf9f65",
        color: "white",
        fontWeight: "bold",
        fontSize: windowWidth < 768 ? "0.7rem" : "1.3rem", // Smaller font size on mobile
        display: "flex",
        justifyContent: "center",
        padding: windowWidth < 768 ? "0.0vw 0" : "1vw 0",
      },
    },
    cells: {
      style: {
        backgroundColor: "#f5f1e8",
        color: "black",
        fontWeight: "bold",
        fontSize: windowWidth < 768 ? "0.7  rem" : "1.05rem", // Smaller font size on mobile
        display: "flex",
        justifyContent: "center",
        padding: windowWidth < 768 ? "0.3vw 0" : "0.5vw 0",
        // textWrap: 'pretty',
      },
    },
  };
  const handleSubmit = async (id, status) => {
    try {
      // Update existing dealership

      const { data: response } = await updateRequirementApi({ status }, id);

      successToast("Requirement updated successfully");
      navigate("/Requirement"); // Redirect after successful submission
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };
  const columns = [
    {
      name: "S.no",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "5%",
    },
    {
      name: "Name",
      selector: (row, index) => row.productName,
      sortable: false,
    },
    {
      name: "Quantity",
      selector: (row, index) => row.quantity,
      sortable: false,
    },
    {
      name: "Approval Status",
      cell: (row, index) => (
        <>
          {row.status == "APPROVED" ? (
            <button
              type="button"
              onClick={() => handleSubmit(`${row._id}`, "DONE")}
              className="btn btn-custom btn-yellow ms-2 action-button"
            >
              Done
            </button>
          ) : (
            <>{row.status}</>
          )}
        </>
      ),
      sortable: false,
    },
    {
      name: "Action",
      /*************  ✨ Codeium Command ⭐  *************/
      /**
       * cell: Render a cell for each row in the table.
       * This cell renders two buttons: one for editing the requirement and another for deleting it.
       * The edit button navigates to /AddRequirements with the requirementId as a query parameter, while the delete button calls handleDelteRequirement with the requirementId.
       */
      /******  2602d2f0-f720-42ea-8fdb-cb74690f5182  *******/
      cell: (row, index) => (
        <>
          <button
            type="button"
            onClick={() => navigate(`/AddRequirement?RequirementId=${row._id}`)}
            className="btn btn-custom btn-yellow action-button"
          >
            <FaPencilAlt />
          </button>
          <button
            type="button"
            onClick={() => handleDelteRequirement(`${row._id}`)}
            className="btn btn-custom btn-yellow ms-2 action-button"
          >
            <FaTrash />
          </button>
        </>
      ),
      sortable: false,
    },
  ];

  const handleGetRequirements = async (skipValue, limitValue, searchQuery) => {
    try {
      let query = `page=${skipValue}&perPage=${limitValue}&userId=${userObj?._id}`;

      if (searchQuery) {
        query = `${query}&q=${searchQuery}`;
      }

      let { data: res } = await getAllRequirements(query);
      if (res.data) {
        setTotalElements(res.totalElements);
        setRequirementArr(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const handleDelteRequirement = async (id) => {
    try {
      if (
        !window.confirm("Are You Sure you want to delete this Requirement ?")
      ) {
        return;
      }

      let { data: res } = await deleteRequirementbyId(id);
      if (res.success) {
        toastSuccess(res.message);
        handleGetRequirements(1, limit);
      }
    } catch (err) {
      errorToast(err);
    }
  };
  const handlePerRowsChange = (newPerPage, page) => {
    handleGetRequirements(page, newPerPage);
    setLimit(newPerPage);
  };
  const handlePageChange = (page) => {
    // alert("Asd")
    if (totalElements / page > 1) {
      setPage(page);
      handleGetRequirements(page, limit);
    }
  };

  const debouncedSave = useCallback(
    // skipValue, limitValue, filterCondition, searchQueryValue, school, company
    debounce(
      (nextValue) => handleGetRequirements(page, limit, nextValue)(),
      1000
    ),

    [] // will be created only once initially
  );

  // highlight-ends

  const handleChange = (event) => {
    const nextValue = event;

    setSearchQuery(nextValue);

    // Even though handleChange is created on each render and executed

    // it references the same debouncedSave that was created initially

    debouncedSave(nextValue);
  };

  useEffect(() => {
    handleGetRequirements(1, limit);
  }, []);

  const [totalPages, setTotalPages] = useState(5);
  return (
    <>
      <div className="container-fluid main-Requirement-container topup-subscription-container">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-10 mb-5">
            <div
              className="row d-flex mt-5 px-3 align-items-center justify-content-between"
              style={{ gap: "2vw 0" }}
            >
              <div className="col-lg-8 col-sm-12 d-flex gap-3">
                <h4 className="yellow h4">Your Requirements</h4>
                <div className="d-flex justify-content-center align-items-center create-button-plus-container">
                  <Link
                    to="/AddRequirement"
                    className="yellow-bg btn text-white subsctiption-card-button"
                  >
                    <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="row d-flex justify-content-end mt-4">
            </div> */}

            <div className="react-dataTable topup-subscription-container">
              <DataTable
                noHeader
                subHeader
                sortServer
                pagination
                responsive
                columns={columns}
                sortIcon={<BiChevronDown />}
                className="react-dataTable main-datatable-container"
                data={RequirementArr}
                paginationServer
                paginationTotalRows={totalElements}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={customStyles}
                // subHeaderComponent={
                //     // <CustomHeader
                //     //     store={store}
                //     //     searchTerm={searchTerm}
                //     //     rowsPerPage={rowsPerPage}
                //     //     handleFilter={handleFilter}
                //     //     handlePerPage={handlePerPage}
                //     //     toggleSidebar={toggleSidebar}
                //     // />
                // }
              />

              {/* <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={totalPages || 1}
                            // activeClassName='active'
                            forcePage={page !== 0 ? page - 1 : 0}
                            // onPageChange={page => handlePagination(page)}
                            // pageClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            // nextClassName={'page-item'}
                            // previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            pageLinkClassName={'page-link'}
                            containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
                        /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { getAllsubscription } from "../../services/Subscription.service";
import {
  buySubscription,
  buySubscriptionforhdfc,
} from "../../services/UserSubscription.service";
import { errorToast, successToast } from "../Utility/Toast";
import { useSelector } from "react-redux";
import "../../assets/css/Subscription.css";
import Modal from "react-bootstrap/Modal";
import phonepae from "../../assets/image/home/phonepae.png";
import hdfc from "../../assets/image/home/hdfc.jpg";

export default function Subscription() {
  const [loading, setLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [subscriptionArr, setSubscriptionArr] = useState([]);
  const [subscriptionObj, setSubscriptionObj] = useState();

  let role = useSelector((state) => state.auth.role);

  const handleGetSubscriptions = async () => {
    try {
      let { data: res } = await getAllsubscription(`role=${role}`);
      if (res.data) {
        setSubscriptionArr(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  useEffect(() => {
    handleGetSubscriptions();
  }, []);

  const handlePlayment = (subscription) => {
    setSubscriptionObj(subscription);
    setPaymentModal(true);
  };

  const handleBuySubscription = async (subscriptionObj, paymentType) => {
    try {
      let obj = {
        ...subscriptionObj,
        paymentType,
      };

      setLoading(true);
      console.log("jfheruithoiertoiejoeri", obj);
      let { data: res } = await buySubscription(obj);

      if (res.success) {
        // successToast(res.message);
        if (paymentType == "phonepay") {
          if (res?.data && res?.data.instrumentResponse) {
            let instrumentResponse = res?.data.instrumentResponse;
            if (instrumentResponse?.redirectInfo) {
              window.location.href = instrumentResponse?.redirectInfo.url;
              return 0;
            }
            setLoading(false);
          } else {
            setLoading(false);

            errorToast(
              "`Phonepe is not working.Please Try Some another Payment Method"
            );
          }
        } else if (paymentType == "justpay") {
          if (res && res.payment_links) {
            let payment_links = res.payment_links;

            // Redirect to payment link if available
            if (payment_links.web) {
              setLoading(false); // Hide loading before redirect
              window.location.href = payment_links.web;
              return 0;
            }
            setLoading(false);
          } else {
            setLoading(false);

            errorToast(
              "Payment gateway is not working. Please try another payment method."
            );
          }
        }
        setLoading(false);

        return 0;
      }
    } catch (err) {
      errorToast(err);
    }
  };

  return (
    <div className="container-fluid subscription-container subscription-card-container">
      {loading && <div className="spinner">Loading...</div>}
      <div className="container">
        <div className="subsctiption-heading">Our Subscriptions</div>
        <div className="subscription-description">
          Buy our subscription to get a steady flow of leads for your business
          and take your business to the next level.
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              {subscriptionArr &&
                subscriptionArr.length > 0 &&
                subscriptionArr.map((el, index) => {
                  return (
                    <div key={index} className="subscription-card">
                      <div className="subscription-card-heading">
                        {el?.name}
                      </div>
                      {/* <div className="subscription-card-price">₹ {el?.price}  <span style={{ fontSize: 15, color: '#603200' }}>+18% GST </span></div> */}
                      <div className="subscription-card-price">
                        ₹ {el?.price ? el.price : "0"}{" "}
                        <span
                          style={{
                            fontSize: 15,
                            color: "#603200",
                          }}
                        >
                          (+ 18% GST)
                        </span>
                      </div>

                      {el?.noOfMonth ? (
                        <div className="subscription-card-days">
                          {el?.noOfMonth}{" "}
                          {el?.noOfMonth > 1 ? "months" : "month"}
                        </div>
                      ) : (
                        <div className="subscription-card-days">
                          Till Subscription
                        </div>
                      )}
                      <div className="subscription-card-description mb-3">
                        {el?.description}
                      </div>
                      <div className="subscription-card-days">
                        {el?.numberOfSales != 0
                          ? `${el?.numberOfSales} Flash sales`
                          : "No Flash sales"}
                      </div>

                      {
                        <div className="subscription-card-description mt-0">
                          For{" "}
                          {el?.saleDays > 1
                            ? `${el?.saleDays} Days`
                            : `${el?.saleDays} Day`}
                        </div>
                      }
                      <div className="subscription-card-days">
                        {el?.numberOfAdvertisement != 0
                          ? `${el?.numberOfAdvertisement} New Arrival`
                          : "No New Arrival"}
                      </div>
                      {el?.advertisementDays  && (
                        <div className="subscription-card-description mt-0">
                          For{" "}
                          {el?.advertisementDays > 1
                            ? `${el?.advertisementDays} Days`
                            : `${el?.advertisementDays} Day`}
                        </div>
                      )}
                      {el?.numberOfBannerImages && (
                        <div className="subscription-card-days">
                          {el?.numberOfBannerImages != 0
                            ? `${el?.numberOfBannerImages} Banner`
                            : "No Banner"}
                        </div>
                      )}

                      {el?.bannerimagesDays  && (
                        <div className="subscription-card-description mt-0">
                          For{" "}
                          {el?.bannerimagesDays > 1
                            ? `${el?.bannerimagesDays} Days`
                            : `${el?.bannerimagesDays} Day`}
                        </div>
                      )}
                      {el?.numberOfOpportunities && (
                        <div className="subscription-card-days">
                          {el?.numberOfOpportunities != 0
                            ? `${el?.numberOfOpportunities} Opportunities`
                            : "No Opportunities"}
                        </div>
                      )}

                      {el?.OpportunitiesDays  && (
                        <div className="subscription-card-description mt-0">
                          For{" "}
                          {el?.OpportunitiesDays > 1
                            ? `${el?.OpportunitiesDays} Days`
                            : `${el?.OpportunitiesDays} Day`}
                        </div>
                      )}

                      {el.messageArr && el.messageArr.length > 0 && (
                        <ul className="subscription-card-message-list pb-5 mb-3">
                          {el.messageArr.map((ele, indexX) => (
                            <li key={indexX}>{ele?.message}</li>
                          ))}
                        </ul>
                      )}

                      <button
                        className="yellow-bg btn text-white subsctiption-card-button"
                        onClick={() => handlePlayment(el)}
                      >
                        Subscribe Now
                      </button>
                      {/* <button className="yellow-bg btn text-white subsctiption-card-button" onClick={() => handleBuySubscriptionforhdfc(el)}>
                                                Subscribe Now
                                            </button> */}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={paymentModal}
        centered
        onHide={() => setPaymentModal(true)}
        className="rounded-5"
      >
        <Modal.Body className="sign-in-modal custom-modal subscription-card-container rounded-5">
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={() => setPaymentModal(false)}
          ></button>

          <h2 className="heading">Pay With</h2>
          <form className="form row">
            {loading ? (
              "Please Wait..."
            ) : (
              <div className="col-12">
                <button
                  type="button"
                  onClick={() =>
                    handleBuySubscription(subscriptionObj, "phonepay")
                  }
                  className="btn btn-custom text-white border-dark py-2 w-100"
                >
                  <img src={phonepae} className=" img-fluid" />
                </button>
                <button
                  type="button"
                  onClick={() =>
                    handleBuySubscription(subscriptionObj, "justpay")
                  }
                  className="btn btn-custom text-white  py-2 w-100 mt-3 border-dark"
                >
                  <img src={hdfc} className=" img-fluid" width="50%" />
                </button>
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
